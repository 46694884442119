<template>
  <div class="top">
    <v-content>
      <v-container>
        <v-row dense justify="center">
          <h1>HANASHIKA</h1>
        </v-row>
        <v-row dense justify="center">
          <h2>会話のきっかけにもう悩まない！雑談ネタを提供する</h2>
        </v-row>
        <hr>

        <v-row dense justify="center">
          <v-col cols="auto">
            <v-card class="main-card">
              <v-card-title class="amber lighten-1">
                <v-icon
                    dark
                    size="42"
                >mdi-home-outline</v-icon>
                <h2 class="display-1 mr-4 white--text font-weight-light">本アプリの概要</h2>
              </v-card-title>
              <v-card-item>
                <div class="description-margin">
                  <div class="list">
                    <li>「毎回ただの愚痴発散の飲み会になってしまう」</li>
                    <li>「合コンで何を話していいかわからない」</li>
                    <li>「自己紹介のネタに１つ話題を付け加えたい」</li>
                    など、どんな話題を出せばいいか思いつかない時に話題のネタをランダムで表示します。
                    <br>
                    雑談のネタに困っている方の悩みを解決します！
                    <br>
                    <br>
                    なるべく大人数で利用すると盛り上がりますが、少人数でもご利用いただけます。
                    <br>
                    <br>
                    ブラウザ上で動作するためアプリをインストールせずに利用可能です。
                  </div>
                </div>
              </v-card-item>
            </v-card>
          </v-col>
        </v-row>

        <v-row dense justify="center">
          <v-btn class="startBtn" @click="gotoMain">すぐに開始する</v-btn>
        </v-row>
        <v-spacer />

        <v-row dense justify="center">
          <v-col cols="auto">
            <v-card class="main-card">
              <v-card-title class="cyan lighten-1">
                <v-icon
                    class="mr-4"
                    dark
                    size="42"
                >
                    mdi-account-multiple-outline
                </v-icon>
                <h2 class="display-1 white--text font-weight-light">利用シーン</h2>
              </v-card-title>
                <v-row>
                  <v-col class="description-card">
                    <div class="description-margin">
                      <p>主に下記の場面で利用すると盛り上がります！</p>
                      <li>合コンや会社の飲み会などを盛り上げたい</li>
                      <li>ディスカッションの練習テーマ</li>
                      <li>レジャー施設等の待ち時間で会話を繋げたい</li>
                      <li>ビジネスの雑談タイムで会話を繋げたい</li>
                      <li>場を盛り上げたい時に</li>
                      <li>こっそりと雑談ネタを仕入れたい時に</li>
                      <li>SNSで雑談を投稿したい時に</li>
                    </div>
                  </v-col>
                </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-3">
          <a href="https://pixabay.com/ja/" target="_blank">画像提供：pixabay</a>
        </v-row>
      </v-container>
    </v-content>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    gotoMain() {
      this.$router.push('/main');
    }
  }
};
</script>