<template>
  <v-content>
    <v-container>
      <v-row>
        <h1>管理画面</h1>
      </v-row>
      <v-row justify="end">
        <v-btn class="ma-2" width="200px" color="success" large @click="signOut">ログアウト</v-btn>
        <v-btn class="ma-2" width="200px" color="success" large @click="$router.push('/')">Topへ戻る</v-btn>
      </v-row>
      <v-row>
        <v-btn color="blue" large @click="$router.push('/maintenance/chat')">雑談テーマの追加・編集・削除</v-btn>
      </v-row>
      <v-row>
        <v-btn color="blue" large @click="$router.push('/maintenance/voicedescription')">音声説明用</v-btn>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import * as firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {
      message: "管理画面メインページ",
    };
  },
  methods: {
    signOut() {
      console.log("ログアウト");
      firebase.auth().signOut();
    }
  }
};
</script>