<template>
  <v-content>
    <v-container>
      <h1>ゲーム選択</h1>
      <v-row justify="end">
        <v-btn class="ma-2" width="200px" color="success" large @click="$router.push('/')">Topへ戻る</v-btn>
      </v-row>
      <v-row>
        <v-col>
          <v-card
              class="mx-auto"
              max-width="344"
              @click="start(voice_items)"
            >
              <v-img src="/img/background01.jpg" height="150px">
                <template v-slot:placeholder>
                  <Progress />
                </template>
              </v-img>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">説明ゲーム</v-list-item-title>
                  <v-list-item-subtitle><p class="regCnt">登録件数:{{voice_items.length}}</p></v-list-item-subtitle>
                  <div class="text--primary">
                      失われた語彙力を取り戻せ！出題されたお題をNGワードを使用せずにで説明して当ててもらうゲーム
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import * as firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {
      voice_items:[],
    };
  },
  created() {
    this.getVoiceDescriptionItemList();
  },
  methods: {
    async getVoiceDescriptionItemList() {
      var db = firebase.firestore();
      this.voice_items = [];
      await db.collection("voice_description").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var docItem = doc.data();
          var docId = doc.id;
          this.voice_items.push({docId, docItem});
        });
      });
    },
    start(arr) {
      this.$router.push({name:"voice_description", params: {itemList: arr}});
    }
  }
};
</script>