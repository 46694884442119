<template>
  <v-app id="inspire">
    <v-container fill-height>
      <v-row>
        <v-chip
          class="ma-2"
          disabled
          label
        >
          ジャンル：{{getThemeType()}}
        </v-chip>
      </v-row>
      <v-row>
        <p class="questionStyle" :style="fontSize">{{getQuestion()}}</p>
      </v-row>
      <v-row justify="center">
        <div class="ngWordField">
          <p class="box-title">NGワード</p>
          <p class="ngWordStyle">{{getNgWord()}}</p>
        </div>
      </v-row>
      <v-row justify="center">
        <v-btn x-large color="blue" @click="changeQuestion" dark>問題を変更する</v-btn>
      </v-row>
      <v-row justify="center">
        <Adsence/>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import vuetify from '../plugins/vuetify';
import Adsence from './Adsense';
export default {
  vuetify,
  data () {
    return {
      num: 0,
      theme_type: "",
      question: "",
      ng_word: "",
    };
  },
  components: {
    Adsence
  },
  props: {
    itemList:null,
  },
  computed: {
    fontSize: function() {
      let fontSize;
      switch (this.$vuetify.breakpoint.name) {
      case 'xs': fontSize = "34px"; break;
      default : fontSize = "5.46vw"; break;
      }
      return "font-size:" + fontSize;
    }
  },
  created() {
    this.changeQuestion();
  },
  methods: {
    nextQuestion() {
      //this.$router.push({name:"voice_description", params: {itemList: this.itemList}});

    },
    changeQuestion() {
      if (this.itemList.length != 0) {
        var min = 0;
        var max = this.itemList.length - 1;

        this.num = Math.floor( Math.random() * (max + 1 - min) ) + min;
      } else {
        alert("問題の取得に失敗しました。トップページへ戻ります。");
        this.$router.push("/");
      }
    },
    getThemeType() {
      this.theme_type = this.itemList[this.num].docItem.theme_type;
      return this.theme_type;
    },
    getQuestion() {
      this.question = this.itemList[this.num].docItem.question;
      return this.question;
    },
    getNgWord() {
      this.ng_word = this.itemList[this.num].docItem.ng_word;
      return this.ng_word;
    },
  }
};
</script>
<style>
.questionStyle {
  margin: 0 auto;
  font-weight: bold;
}
.ngWordField {
  position: relative;
  margin: 2em 0;
  padding: 25px 10px 7px;
  text-align: center;
  font-weight: bold;
  color: #6091d3;/*文字色*/
  background: #FFF;
  border: solid 3px #6091d3;/*線*/
  min-width: 300px;
}
.ngWordField .box-title {
    position: absolute;
    display: inline-block;
    top: -2px;
    left: -2px;
    padding: 0 9px;
    height: 25px;
    line-height: 25px;
    font-size: 17px;
    background: #6091d3;
    color: #ffffff;
    font-weight: bold;
}
.ngWordField .ngWordStyle {
  margin: 0 auto;
  font-size: 20px;
  font-weight: bold;
  color: red;
}
.cardMain {
  background-color: #B5C7D3;
}
</style>