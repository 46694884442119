<template>
  <v-content>
    <v-container>
      <v-row>
        <h1>管理画面</h1>
      </v-row>
      <v-row justify="end">
        <v-btn class="ma-2" width="200px" color="success" large @click="signOut">ログアウト</v-btn>
      </v-row>
      <router-link to="/maintenance">管理画面トップへ戻る</router-link>
      <v-row>
        <p v-if="items.length==0">登録されているデータがありません</p>
        <p v-else>登録済音声説明問題件数:{{items.length}}件</p>
      </v-row>
      <v-row>
        <p>{{message}}</p>
      </v-row>

      <v-row justify="center">
        <v-container fluid>
          <template>
            <v-card>
              <v-card-title>
                説明問題一覧
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2 ml-10"
                    v-bind="attrs"
                    v-on="on"
                    @click="getLatestId"
                  >新規登録</v-btn>
                </template>
                <v-card>
                  <v-card-title>{{formTitle}}</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.id" label="ID"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.theme_type" label="ジャンル"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field v-model="editedItem.question" label="問題"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field v-model="editedItem.description" label="説明"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field v-model="editedItem.ng_word" label="NGワード"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-btn @click="save">{{formTitle}}</v-btn>
                        <v-btn class="ml-6" @click="close">閉じる</v-btn>
                      </v-row>
                    </v-container>
                  </v-card-text>

                </v-card>
              </v-dialog>

              <v-data-table
                no-results-text="検索結果なし"
                sort-by="docItem.id"
                :footer-props="{itemsPerPageOptions: [50, 100, -1]}"
                :items-per-page="50"
                :headers="headers"
                :items="items"
                :search="search"
              >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              </v-data-table>
            </v-card>
          </template>
        </v-container>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import * as firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {
      search: '',
      dialog: false,
      headers: [
        {text: 'document', value: 'docId'},
        {
          text: 'id',
          align: 'start',
          sortable: true,
          value: 'docItem.id',
        },
        { text: 'text', value: 'docItem.theme_type' },
        { text: 'question', value: 'docItem.question' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      message: "インポートするデータを選択してください",
      workers: [],
      items:[],
      defaultItem: {
        id: '',
        theme_type: '',
        question: '',
        description: '',
        ng_word: '',
      },
      editedIndex: -1,
      editedDocument: '',
      editedItem: {
        id: '',
        theme_type: '',
        question: '',
        description: '',
        ng_word: '',
      },
    };
  },
  created() {
    this.getThemeList();
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? '新規登録' : '更新';
    },
  },
  methods: {
    async getThemeList() {
      this.items = [];
      var db = firebase.firestore();
      await db.collection("voice_description").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var docItem = doc.data();
          var docId = doc.id;
          this.items.push({docId, docItem});
        });
      });
    },
    async getLatestId() {
      console.log("最新ID取得");
      var db = firebase.firestore();
      let latestId = await db.collection("voice_description").orderBy('id').limit(1).get();
      if (latestId != null) {
        console.log(latestId);
        this.editedItem.id = latestId;
      }
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedDocument = item.docId;
      this.editedItem = Object.assign({}, item.docItem);
      this.dialog = true;
    },
    deleteItem (item) {
      const index = this.items.indexOf(item);
      console.log(item);
      if (confirm('Are you sure you want to delete this item?')) {
        this.items.splice(index, 1);
        firebase.firestore().collection('voice_description').doc(item.docId).delete();
        this.getThemeList();
      }
    },
    async save () {
      var db = firebase.firestore();

      if (this.editedIndex > -1) {
        // 更新処理
        let val = await db.collection("voice_description").doc(this.editedDocument);
        await val.update({
          id: this.editedItem.id,
          theme_type: this.editedItem.theme_type,
          question: this.editedItem.question,
          description: this.editedItem.description,
          ng_word: this.editedItem.ng_word
        });
      } else {
        // 新規登録
        db.collection("voice_description").add({
          id: this.editedItem.id,
          theme_type: this.editedItem.theme_type,
          question: this.editedItem.question,
          description: this.editedItem.description,
          ng_word: this.editedItem.ng_word
        })
          .then(function(docRef) {
            console.log(docRef);
          })
          .catch(function(error) {
            this.message = "新規登録に失敗しました";
            console.log(error);
          });
      }
      this.getThemeList();
      this.close();
    },
    close () {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    signOut() {
      console.log("ログアウト");
      firebase.auth().signOut();
    }
  }
};
</script>